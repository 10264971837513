<template>
    <div class="cta-caja cta-caja--yellow" @click="clicking">
      <product-added-dialog-ok :showProductAddedConfirmation="showProductAddedConfirmation"  />
      <div class="cta-caja__cover">
        <img
          class="cta-caja__cover-desk"
          src="https://images.hellobb.net/perfect-prep-desktop.jpg"
          alt=""
        />
        <img
          class="cta-caja__cover-mobile"
          src="https://images.hellobb.net/perfect-prep-responsive.png"
          alt=""
        />
      </div>
      <div class="cta-caja__content">
        <h4 class="cta-caja__title">Preparador de biberones Perfect Prep</h4>
        <div class="cta-caja__description">
          <p>Tan fácil como preparase un café.</p>
        </div>
        <div class="cta-caja__actions">
          <loading-button v-if="loading" :class="'button-loading--sm'" />
          <button
            v-else
            class="button button--accent button--sm button--ico"
            @click.stop="addProduct('Constructor - Banner Horitzontal')"
            :disabled="added"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
      <modal-dialog
          :show="showProductAddedDialog"
          @close="showProductAddedDialog = false"
          target-class="modal__card--mini"
      >
        <product-added-to-list/>
      </modal-dialog>
    </div>
  </template>
  
  <script>
  import Product from "./Mixins/Product";
  import LoadingButton from "../../../components/LoadingButton";
  import ModalDialog from "../../../components/ui/ModalDialog";
  import ProductAddedToList from "../../../components/ProductAddedToList";
  import ProductAddedDialogOk from "../../../components/ProductAddedDialogOk";
  
  export default {
    name: "CtaPerfectPrep",
    mixins: [Product],
    components: {
      LoadingButton,
      ModalDialog,
      ProductAddedToList,
      ProductAddedDialogOk
    },
    computed: {
      buttonText() {
        return this.added ? "Añadido" : "Añadir a mi lista";
      },
    },
    data() {
      return {
        showProductAddedConfirmation: false,
      };
    },
    created() {
      this.product = {
        id: 38831,
        "brand-slug": "tommee-tippee",
        name: "Prepara biberones Perfect Prep",
        retailerName: "HelloBB",
        productRetailers: [{"name": "HelloBB"}]
      };
    },
    methods: {
      clicking()
      {
        mixpanel.track("Click element constructor", {
          "Name": "Prepara biberones Perfect Prep",
          "Section": "Objetos de calidad que seguro te duran para el siguiente bebé",
          "Section number": 5,
          "Type": "Banner - Horizontal",
          "Content": "Ecommerce"
        });
        this.browseProduct();
      }
    }
  };
  </script>